<template>
  <div class="card-login">
    <img :src="plant">
    <slot></slot>
  </div>
</template>

<script>


export default {
  data() {
    return {
      plant: require("@/assets/Leafmatic_logo-02.svg"),
  
    };
  },
}
</script>



<style scoped lang="scss">
@import "../../main.scss";
.card-login {
 
 
  background-color: $blue-green-light;
  width: 100%;
  height: 100vh;
  
}

img{
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding-top: 2rem;
  margin-bottom: 6rem;
}
</style>
