<template>
  <div class="grid-card">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
@import '@/main.scss';
.grid-card {
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  margin: 0;
  width: 100%;
  background-color: $main-colour-light;
}
</style>
