const sensorsImportance = {
  "meas_airTemperature": true,
  "meas_atmPressure": true,
  "meas_cAC": false,
  "meas_cLEDS": false,
  "meas_co2concentration": true,
  "meas_lightALS": true,
  "meas_lightIR": true,
  "meas_o2concentration": true,
  "meas_tCORE": false,
  "meas_vBUS": false,
  "meas_tPSU": false,
  "meas_waterEC_ADC": false,
  "meas_waterEC_T_ADC": false,
  "meas_waterPH": true,
  "meas_waterPH_ADC": false
}

export default sensorsImportance;
