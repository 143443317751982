import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./router/middleware";
import store from "./store/index.js";
import BaseCard from "./components/ui/BaseCard.vue";
import GridCard from "./components/ui/GridCard.vue";
import BaseButton from "./components/ui/BaseButton.vue";
import MobileButton from "./components/ui/MobileButton.vue";
import SmallButton from "./components/ui/SmallButton.vue";
import NoNameButton from "./components/ui/NoNameButton.vue";
import BaseSpinner from "./components/ui/BaseSpinner.vue";
import BaseDialog from "./components/ui/BaseDialog.vue";
import InfoLine from "./components/ui/InfoLine.vue";
import ExpandButton from "./components/ui/ExpandButton.vue";

const app = createApp(App);

app.use(router);
app.use(store);
NoNameButton
app.component("base-card", BaseCard);
app.component("grid-card", GridCard);
app.component("base-button", BaseButton);
app.component("mobile-button", MobileButton);
app.component("small-button", SmallButton);
app.component("no-name-button", NoNameButton);
app.component("base-spinner", BaseSpinner);
app.component("base-dialog", BaseDialog);
app.component("info-line", InfoLine);
app.component("expand-button", ExpandButton);


app.mount("#app");
