<template>
  <div class="plant-container">
    <div class="plant">
		<video autoplay loop muted playsinline>
		<source src="@/assets/animations/Plant_smaller.mp4" type="video/webm">
		Przeglądarka nie obsługuje odtwarzania wideo.
		</video>
		<div class="mask"></div>
    </div>
	<div class="text">
		<p class="first">Trwa</p>
		<p ref="authorizationText">{{ animatedText }}</p>
	</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
		dotCount: 0,
		dotInterval: null,
    };
  },
  mounted() {
    this.startDotAnimation();
  },
  computed: {
    animatedText() {
      return "autoryzacja" + ".".repeat(this.dotCount);
    },
  },

  methods: {
    startDotAnimation() {
      this.dotInterval = setInterval(() => {
        this.dotCount += 1;

        if (this.dotCount > 3) {
          this.dotCount = 0; // Po trzech kropkach wracamy do zera
        }
      }, 1250);
    },
  },
  beforeUnmount() {
    clearInterval(this.dotInterval);
  },
};
</script>

<style scoped lang="scss">
  @import "@/main.scss";


  .plant{
		position: relative;
		height: 22rem;
	}


	video {
		height: 10rem;
		margin:  5rem 0rem 0rem 3rem;
		
	}
	.mask{
		position: absolute;
		height: 10px;
		width: 100%;
		background-color: $blue-green-light;
		margin-top: -1rem;
		z-index: 1000

	}

	.plant-container{
		
		display: grid;
		grid-template-columns: 1fr 3fr;
	}

	.text{
		padding-top: 10rem;
		padding-left: 3rem;
	}

	p{
		margin-top: -1.5rem;
		margin-bottom: 0px;
		color: $main-colour-dark;
		font-family: BwGradual;
		font-size: 2.5rem;
		padding: 0px;
		transition: opacity 0.5s ease-in-out; // Dodaj przejście opacity
		
	}
	.first{
		font-family: BwGradualnormal;
	}





</style>