<template>
  
    <div :class="{'welcome': !isMobile, 'welcome-mobile': isMobile}">
      <div v-if="isMobile" >
      <img :class="{'img-mobile': isMobile}" :src="img" />
      </div> 
      <div  :class="{'left-pannel': !isMobile, 'botom-mobile': isMobile}">
      <h1>Sprawdź</h1>
      <h1>pomiary swoich</h1>
      <h1>roślin<span class="arrow">→</span></h1>
      <h5>System monitorowania i zdalnego <br>sterowania w rolnictwie i ogrodnictwie</h5>
      <base-button v-if="!isMobile" @click="navigateToLogin">Zaloguj</base-button>
      <mobile-button v-if="isMobile" @click="navigateToLogin">Zaloguj</mobile-button>
    </div>
    <div v-if="!isMobile">
      <img :src="img" />
    </div> 
      
    </div>
  </template>

<script>
export default {
  data() {
    return {
      img: require("@/assets/Ilustracja.png"),
  
    };
  },


  methods: {
    navigateToLogin() {

      this.$router.push('/login');
    
    },
  },
  computed: {
    isMobile() {
      return this.$store.state.responsive.isMobile;
    },
  },


}
</script>



<style scoped lang="scss">
@import "../main.scss";



h1, h5{
  font-family: BwGradual;
  color: $main-colour-dark;
}

h1 {
  margin-bottom: -0.9rem;
}

h5{
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.left-pannel{
  margin-top: 8rem;
}

.botom-mobile{
  margin: 2rem;

}

.arrow{
  vertical-align: top;
  line-height: 2.5rem;
  font-size: 5rem;
}



.welcome{
  display: grid;
  grid-template-columns: 1fr 1fr;
  
}
.welcome-mobile{
  display: grid;
  grid-template-columns: 1fr;
  
}

img{
  max-width: 80%;
}

.img-mobile{
  max-height: 30vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
 
}

</style>