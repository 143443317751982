<template>
  <div class="main">
    <the-header></the-header>
    <div :class='{"content": !isMobile, "content-mobile": isMobile}'>
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <Component :is="Component"/>
        </transition>
      </router-view>
    </div>
  </div>
  <mobile-menu class="mobile-menu-container"  :class="{ 'hidden': isMenuHidden }" v-if="isMobile && isLoggedIn">
    
  </mobile-menu>
</template>

<script>
// zacząć od emitowania zdażeń do górnego menu
import TheHeader from "./components/layout/TheHeader.vue";
import MobileMenu from "./components/layout/MobileMenu.vue";
export default {
  components: {
    TheHeader,
    MobileMenu
  },
  data() {
    return {
      currentTime: 0,
      isMenuHidden: false
    };
  },
  
  methods: {
    handleWindowResize() {
      this.$store.dispatch('responsive/checkIsMobile');
    },
    handleScroll() {
            
            this.isMenuHidden = window.scrollY > this.lastScrollPosition;
            this.lastScrollPosition = window.scrollY;
        }
  },
  
  computed: {
    isMobile() {
      return this.$store.state.responsive.isMobile;
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    },
  },
  created() {
    window.addEventListener('resize', this.handleWindowResize)
    this.handleWindowResize;
    this.$store.dispatch("tryLogin");
    setInterval(() => {
      this.currentTime = Date.now();
    }, 1000);
    
  },
  mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
  unmounted() {
    window.removeEventListener('resize', this.handleWindowResize);
  },
  
  

 
};
</script>

<style lang="scss">
@import "./main.scss";
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: Arial, sens-serif;
  font-weight: 400;
  line-height: 1.75;
}

.content {
  margin: 4rem;
  margin-top: 0.8rem;
  margin-bottom: 5.5rem;
}
.content-moobile {

  margin: 0rem;
}
.mobile-menu-container {
  position: fixed;
  bottom: 0;
  transition: transform 0.3s ease; 
}

.mobile-menu-container.hidden {
            transform: translateY(100%);
        }

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease-out;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>
