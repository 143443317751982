<template>
  <div class="info-line">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
@import "../../main.scss";
.info-line {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  margin: 1rem;
  color: $error-colour;
}
</style>
