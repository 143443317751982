export default {
  farms(state) {
    return state.farms;
  },
  cropTemplates(state) {
    return state.cropTemplates
  },
  graphs(state) {
    return state.graphs
  },
  hasFarms(state) {
    return state.farms && state.farms.length > 0;
  },
  dataError(state) {
    return state.dataError;
  },
};
