import { createStore } from "vuex";

import authModule from "./modules/auth/index.js";
import dataModule from "./modules/data/index.js";
import responsiveModule from "./modules/responsive/index.js";


const store = createStore({
  modules: {
    auth: authModule,
    data: dataModule,
    responsive: responsiveModule
  },
});

export default store;