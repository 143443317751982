<template>
  <button  
      @mouseover="setHover(true)"
      @mouseout="setHover(false)"
       v-if="!link" :class="mode">
    <img v-if="icon" :src="isHovered ? this[icon + 'Active'] : this[icon]" alt="Icon">
    <slot></slot>
  </button>
  <router-link v-else :to="to" :class="mode">
    <slot></slot>
  </router-link>
</template>

<script>


export default {
  props: {
    mode: {
      type: String,
      required: false,
      default: null,
    },
    link: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: String,
      required: false,
      default: "/",
    },
    icon: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      AddTemplate: require("@/assets/icons/AddTemplateInactive.svg"),
      AddTemplateActive: require("@/assets/icons/AddTemplate.svg"),
      RemoveTemplate: require("@/assets/icons/RemoveTemplate.svg"),
      RemoveTemplateActive: require("@/assets/icons/RemoveTemplateActive.svg"),
      Edit: require("@/assets/icons/Edit.svg"),
      EditActive: require("@/assets/icons/EditActive.svg"),
      Save: require("@/assets/icons/Save.svg"),
      SaveActive: require("@/assets/icons/SaveActive.svg"),
      isHovered: false,
    };
  },
  methods: {
    setHover(value) {
      this.isHovered = value;
    },
  },

 


};
</script>

<style scoped lang="scss">
@import "@/main.scss";


button {
  display: flex;
  align-items: center;
  justify-content: center;
  
}

img{
  width: 1.3rem;
  margin-right: 0.5rem;
}

button,
a {
  
  text-decoration: none;
  padding: 0.1rem 1rem;
  font-family: BwGradual;
  background-color: $white;
  border: 1px solid $gray-colour-light;
  color: $main-colour-dark;
  cursor: pointer;
  border-radius: 3px;
  
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s; 
}

a:hover,
a:active,
button:hover,
button:active {
  background-color: $main-colour-dark;
  border-color: $main-colour-dark;
  color: $blue-green-light;
}


.flat {
  background-color: transparent;
  color: $main-colour-light;
  border: none;
}

.outline {
  background-color: transparent;
  border-color: $main-colour;
  color: $main-colour;
}

.flat:hover,
.flat:active,
.outline:hover,
.outline:active {
  background-color: $main-colour-light;
}

</style>
