import mutations from "./mutations.js";
import actions from "./actions.js";

export default {
  namespaced: true,
  state() {
    return {
      isMobile: window.innerWidth < 768, 
    };
  },
  mutations,
  actions,
};