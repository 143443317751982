<template>
  <button v-if="!link" :class="mode">
    <slot></slot>
  </button>
  <router-link v-else :to="to" :class="mode">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      required: false,
      default: null,
    },
    link: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: String,
      required: false,
      default: "/",
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/main.scss";
button,
a {
  text-decoration: none;
  padding: 0.3rem 1.5rem;
  font-family: BwGradual;
  background-color: $white;
  border: 1px solid $gray-colour-light;
  color: $main-colour-dark;
  cursor: pointer;
  border-radius: 5px;
  display: inline-block;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s; 
}

a:hover,
a:active,
button:hover,
button:active {
  background-color: $main-colour-dark;
  border-color: $main-colour-dark;
  color: $white;
}

.flat {
  background-color: transparent;
  color: $main-colour-light;
  border: none;
}

.outline {
  background-color: transparent;
  border-color: $main-colour;
  color: $main-colour;
}

.flat:hover,
.flat:active,
.outline:hover,
.outline:active {
  background-color: $main-colour-light;
}
</style>
