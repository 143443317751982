const sensors = {
  "meas_airHumidity": "Wilgotność Powietrza",
  "meas_airTemperature": "Temperatura Powietrza",
  "meas_atmPressure": "Ciśnienie Atmosferyczne",
  "meas_cAC": "Prąd Przetwornika AC",
  "meas_cLEDS": "Prąd LEDów",
  "meas_co2concentration": "Stężenie dwutlenku węgla",
  "meas_lightsALS": "Oświetlenie",
  "meas_lightsIR": "Światło Podczerwone",
  "meas_o2concentration": "Stężenie tlenu",
  "meas_tCORE": "Temperatura Rdzenia",
  "meas_vBUS": "Napięcie Magistrali",
  "meas_tPSU": "Temperatura Zasilacza",
  "meas_waterEC_ADC": "Przewodność Elektryczna Wody (ADC)",
  "meas_waterEC_T_ADC": "Temperatura Wody (ADC)",
  "meas_waterPH": "pH Wody",
  "meas_waterPH_ADC": "pH Wody (ADC)"
};

export default sensors;
