<template>
  <div>
    <ul :class="{'info-card-container': !isMobile, 'info-card-container-mobile': isMobile}">
      <template v-for="(measurement, key) in dataToShow">
        <GridCard
          v-if="showNotImportant"
          :key="key"
        >
          <div class="card-header">
            <h5>{{ changeName(key) }}</h5>
            <div class="border">
          </div>
          </div>
          
          <div v-for="(measure, index) in measurement" :key="index">
            
            <div >
                
              <div v-for="(data, index) in measure" :key="index">
               
               <div v-if="(data.name )">
                  
                  <div v-if="isInImportantValues(key, data.name)">
                    
                    <div v-if="data.name ==='Napięcie Magistrali'">
                      <p  class="parametr">
                      
                      Oświetlenie Propagatora: 
                      <span class="value"> 
                                  
                      <img
                        class="light"
                        v-if="data.value > 10000"
                        :src="sun"
                      />
                      <img
                        class="light"
                        v-else
                        :src="moon"
                      />
                      </span>
                    </p> 
                    </div>




                    <div v-if="data.name ==='pH'">
                      <p  class="parametr">
                      
                      pH:     
                    </p> 
                    <p class="value"> 
                      5.7 pH
                      <img
                        class="trend-arrow"
                        :src="trendUp"
                      />
                    </p>


                    <p  class="parametr">
                      
                      EC:     
                    </p> 
                    <p class="value"> 
                      2.0 EC
                      <img
                        class="trend-arrow"
                        :src="trendDown"
                      />
                    </p>
                    </div>





                    <div v-else>
                    <p  class="parametr">{{ data.name}}
                      <span v-if="data.node.slice(-3) === '001'">na 20cm</span>
                      <span v-if="data.node.slice(-3) === '002'">na 100cm</span>  
                      <span v-if="data.node.slice(-3) === '003'">na 200cm</span>
                      <span v-if="data.node.slice(-3) === '004'">na 300cm</span>      
                      : </p> 
                      <p class="value"> 
                        
                        {{  data.value + " " + data.units }} 
                        
                        
                      <img
                        class="trend-arrow"
                        v-if="data.value_5m < data.value"
                        :src="trendUp"
                      />
                      <img
                        class="trend-arrow"
                        v-if="data.value_5m > data.value"
                        :src="trendDown"
                      />
                    </p>
                  </div>
                  </div>
              </div>
            </div>
            </div>
          </div>
        </GridCard>
      </template>
    </ul>
  </div>
</template>

<script>
import GridCard from "@/components/ui/GridCard.vue";
import { format, utcToZonedTime } from "date-fns-tz";
export default {
  components: {
    GridCard,
  },
  data() {
    return {
      timeZone: "Europe/Warsaw",
      trendUp: require("@/assets/icons/TrendUp.svg"),
      trendDown: require("@/assets/icons/TrendDown.svg"),
      sun: require("@/assets/icons/sun.svg"),
      moon: require("@/assets/icons/moon.svg"),
      userId: localStorage.getItem('userId') || '',
      nameMap: {
        propagator: "Propagator",
        general: "Informacje Ogólne",
        nourishment: "Pożywka",
        weather: "Warunki pogodowe",
      },
      importantValues: {
        propagator: [
          "Wilgotność Powietrza",
          "Temperatura Powietrza",
          "Napięcie Magistrali"
        ],
        nourishment: [
          "pH"
        ],
        general: [
          "Wilgotowść Powietrza",
          "Temperatura Powietrza",
          "Ciśnienie Atmosferyczne",
          "Stężenie dwutlenku węgla"
        ],
        weather: [
          
          "Temperatura na zewnątrz",
        
        ],
        
      }
      
    }
  },
  props: {
    dataToShow: {
      type: Object,
      required: false,
    },
    showNotImportant: {
      type: Boolean,
      required: false,
      default: false,
    },
    valueToShow:{
     type: Array, 
    },
    farmName: {
      type: String
    }
  },
  methods: {
    
    
    isInImportantValues(group, value) {

      if (this.farmName == 'Szklarnia 6'){
        return true
      }
      
      if (!group || !value || !this.importantValues || !this.importantValues[group]) {
        return false;
      }

      

    return this.importantValues[group].includes(value);
},


    changeName(name) {

      const newName = this.nameMap[name] || name;

      return newName;
      },
    timeFormater(time) {
      const originalDate = time;
      const timeZone = this.timeZone;
      const zonedDate = utcToZonedTime(originalDate, timeZone);


      return format(zonedDate, "HH:mm", { timeZone });
    },
  },
  computed: {
    isMobile() {
      return this.$store.state.responsive.isMobile;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/main.scss";

h5{
  text-transform: uppercase;
  font-family: BwGradualnormal;
}

.info-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  margin: 0;
  
}

.info-card-container-mobile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin: 0;
  
}
.trend-arrow {
  width: 1rem;
}
.light{
  width: 1rem;
  margin: auto;
}

.parametr {
  margin: 0.5rem 1rem;
  font-size: 1rem;
  font-family: BwGradualnormal;
  color: $main-colour-dark;
}

.value{
  font-family: BwGradual;
  color: $main-colour-dark;
  font-size: 1.2rem;
  margin-left: 1rem;
  margin-top: -0.8rem;
}
</style>
