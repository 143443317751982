let timer;
import api from "../api.js";

export default {
  async login(context, payload) {
    return context.dispatch("auth", {
      ...payload,
      mode: "login",
    });
  },
  async signup(context, payload) {
    return context.dispatch("auth", {
      ...payload,
      mode: "signup",
    });
  },
  async auth(context, payload) {
    const mode = payload.mode;
    let url = `${api}auth/token/`;

    if (mode === "signup") {
      url = `${api}admin/set_password`;
    }
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `username=${payload.email}&password=${payload.password}`,
    });

    
    const responseData = await response.json();
    
    if (!response.ok) {
      const error = new Error(
        responseData.message ||
          "Nieprawidłowe dane logowania.",
      );
      throw error;
    }
    if (responseData === "activated") {
      return "Aktywowano użytkownika Możesz się zalogować"
    }
    
    if (mode==="login" && responseData.is_active){
      const expiresIn = +responseData.exp_time * 1000;
      const expirationDate = new Date().getTime() + expiresIn;

      localStorage.setItem("token", responseData.access_token);
      localStorage.setItem("userId", responseData.id);
      localStorage.setItem("tokenExpiration", expirationDate);

      timer = setTimeout(function () {
        context.dispatch("autoLogout");
      }, expiresIn);

      context.commit("setUser", {
        token: responseData.access_token,
        userId: responseData.id,
      });
    } else {
      return "not active"
    }
    
    
    
  },
  tryLogin(context) {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const tokenExpiration = localStorage.getItem("tokenExpiration");

    const expiresIn = +tokenExpiration - new Date().getTime();
    if (expiresIn < 0) {
      context.dispatch("autoLogout");
    }

    timer = setTimeout(function () {
      context.dispatch("autoLogout");
    }, expiresIn);

    if (token && userId) {
      context.commit("setUser", {
        token: token,
        userId: userId,
      });
    }
  },
  logout(context) {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("tokenExpiration");

    clearTimeout(timer);

    context.commit("setUser", {
      token: null,
      userId: null,
    });
  },
  autoLogout(context) {
    context.dispatch("logout");
    context.commit("setAutoLogout");
  },
};
