<template>
  <div :class="{'login dark-overlay': !isMobile, 'login-mobile': isMobile}">
    <base-dialog :show="!!error" title="Błąd!" @close="handleError">
      <p >{{ error }}</p>
    </base-dialog>
    <base-dialog :show="!!message" title="Sukces!" @close="handleMessage">
      <p >{{ message }}</p>
    </base-dialog>

    <login-card v-if=" !isMobile" class="element-to-remain-visible">
    <div v-if="isLoading">
      <div v-if="!isMobile"><plant-animation/></div>

    </div>
      <form v-else  @submit.prevent="submitForm">

        <div v-if="this.mode ==='signup'">
          <p>Musisz wprowadzić nowe hasło dla: {{ this.email }}</p>
        
          <p class="password-desc">(Hasło powinno składać się z co najmniej 8 znaków)</p>
        </div>

        <div v-if="this.mode == 'login'" class="form-control">
          <label for="email">Adres e-mail</label>
          <input :class="{ 'not-valid-input': !emailIsValid }" type="email" id="email" v-model.trim="email" />
        </div>
        <div v-else class="form-control">
          <label for="password">Hasło</label>
          <input :class="{ 'not-valid-input': !passwordIsValid }" type="password" id="password" v-model.trim="newPassword" />
        </div>

        <div v-if="this.mode == 'login'" class="form-control">
          <label for="password">Hasło</label>
          <input :class="{ 'not-valid-input': !passwordIsValid }" type="password" id="password" v-model.trim="password" />
        </div>
        <div v-else class="form-control">
          <label for="replece-password">Powtórz Hasło</label>
          <input :class="{ 'not-valid-input': !passwordIsValid }" type="password" id="replece-password" v-model.trim="newRepeatPassword" />
        </div>

        <div v-if="this.mode == 'login'" class="bottom-section">
          <div v-if="!emailIsValid && !passwordIsValid">
            <p  class="error">* Wpisz poprawny e-mail i hasło</p>
          </div>
          <div v-else>
            <p v-if="!emailIsValid" class="error">* Wpisz poprawny e-mail.</p>
            <p v-if="!passwordIsValid" class="error">* Wpisz poprawne hasło.</p>
          </div>
          
          <div  class="login-page-button"><login-button>Zaloguj się</login-button></div>
        </div>

        <div v-else class="bottom-section">
          <div v-if="!emailIsValid && !passwordIsValid">
            <p  class="error">* Wpisz poprawny e-mail i hasło</p>
          </div>
          <div v-else>
            <p v-if="!emailIsValid" class="error">* Wpisz poprawny e-mail.</p>
            <p v-if="!passwordIsValid" class="error">* Wpisz poprawne hasło.</p>
          </div>
          
          <div  class="login-page-button"><login-button>Zapisz nowe Hasło</login-button></div>
        </div>

      </form>
    </login-card>



    <login-card-mobile v-else class="element-to-remain-visible">
    <div v-if="isLoading">
      <div v-if="!isMobile"><plant-animation/></div>

    </div>

    
    
      <form v-else  class="form-mobile" @submit.prevent="submitForm">
        <div v-if="this.mode ==='signup'">
        <p>Musisz wprowadzić nowe hasło dla: {{ this.email }}</p>
      
        <p class="password-desc">(Hasło powinno składać się z co najmniej 8 znaków)</p>
      </div>
        <div v-if="this.mode == 'login'" class="form-control">
          <label for="email">Adres e-mail</label>
          <input :class="{ 'not-valid-input': !emailIsValid }" type="email" id="email" v-model.trim="email" />
        </div>
        <div v-else class="form-control">
          <label for="password">Hasło</label>
          <input :class="{ 'not-valid-input': !passwordIsValid }" type="password" id="password" v-model.trim="newPassword" />
        </div>

        <div v-if="this.mode == 'login'" class="form-control">
          <label for="password">Hasło</label>
          <input :class="{ 'not-valid-input': !passwordIsValid }" type="password" id="password" v-model.trim="password" />
        </div>
        <div v-else class="form-control">
          <label for="replece-password">Powtórz Hasło</label>
          <input :class="{ 'not-valid-input': !passwordIsValid }" type="password" id="replece-password" v-model.trim="newRepeatPassword" />
        </div>

        <div v-if="this.mode == 'login'" class="bottom-section">
          <div v-if="!emailIsValid && !passwordIsValid">
            <p  class="error">* Wpisz poprawny e-mail i hasło</p>
          </div>
          <div v-else>
            <p v-if="!emailIsValid" class="error">* Wpisz poprawny e-mail.</p>
            <p v-if="!passwordIsValid" class="error">* Wpisz poprawne hasło.</p>
          </div>
          
          <div  class="login-page-button"><login-button-mobile>Zaloguj się</login-button-mobile></div>
        </div>

        <div v-else class="bottom-section">
          <div v-if="!emailIsValid && !passwordIsValid">
            <p  class="error">* Wpisz poprawny e-mail i hasło</p>
          </div>
          <div v-else>
            <p v-if="!emailIsValid" class="error">* Wpisz poprawny e-mail.</p>
            <p v-if="!passwordIsValid" class="error">* Wpisz poprawne hasło.</p>
          </div>
          
          <div  class="login-page-button"><login-button-mobile>Zapisz nowe Hasło</login-button-mobile></div>
        </div>
      </form>
    </login-card-mobile>
    
  </div>
</template>

<script>
import LoginCard from './ui/LoginCard.vue';
import LoginCardMobile from './ui/LoginCardMobile.vue';
import LoginButton from './ui/LoginButton.vue'
import LoginButtonMobile from './ui/LoginButtonMobile.vue'
import PlantAnimation from './ui/PlantAnimatian.vue'
import BaseDialog from './ui/BaseDialog.vue';

export default {
  components:{
    LoginCard,
    LoginCardMobile,
    LoginButton,
    LoginButtonMobile,
    PlantAnimation,
    BaseDialog
},
  data() {
    return {
      email: "",
      password: "",
      newPassword:"",
      newRepeatPassword: "",
      formIsValid: true,
      mode: "login",
      isLoading: false,
      error: null,
      message: null,
      emailIsValid: true,
      passwordIsValid: true,
      
    };
  },

  methods: {
    
  

  
    async submitForm() {

      if (this.mode === "login") {

      
        this.formIsValid = true;
        this.emailIsValid = true;
        this.passwordIsValid = true;


        
        if (this.email === "" ||
          !this.email.includes("@") ||
          this.password.length < 6) {
            if (
              this.email === "" ||
              !this.email.includes("@") 
              ){
                this.formIsValid = false;
                this. emailIsValid = false
                
              }

              if(
                this.password.length < 6
              ) {
                this.formIsValid = false;
                this. passwordIsValid = false
                
              }
              return
          } 
        
        this.isLoading = true;
        const actionPayload = {
          email: this.email,
          password: this.password,
        };

        try {
          if (this.mode === "login") {
            const response = await this.$store.dispatch("login", actionPayload);
            if (response === "not active") {
              this.mode = "signup"
              this.isLoading = false;
              return
            }
          } else {
            await this.$store.dispatch("signup", actionPayload);
          }
          const redirectUrl = "/" + (this.$route.query.redirect || "");
          this.$router.replace(redirectUrl);
        } catch (err) {
          this.error = err.message || "Błąd Autoryzacji.";
        }

        this.isLoading = false;
      }
      else if (this.mode === "signup"){

        if (this.newPassword.length < 8 || this.newPassword !== this.newRepeatPassword) {
              this.formIsValid = false;
              this.passwordIsValid = false
              return
          } 
        
        this.isLoading = true;
        const actionPayload = {
          email: this.email,
          password: this.newPassword,
          
        };
        try {
          
          this.message = await this.$store.dispatch("signup", actionPayload);
          
         
        } catch (err) {
          this.error = err.message || "Błąd Autoryzacji.";
        }
        this.email = ""
        this.password = ""
        this.newPassword = ""
        this.newRepeatPassword = ""
        this.mode = "login"
        this.isLoading = false;
      }
    },
      
    switchAuthMode() {
      if (this.mode === "login") {
        this.mode = "signup";
      } else {
        this.mode = "login";
      }
    },
    handleError() {
      this.error = null;
    },
    handleMessage() {
      this.message = null;
    },
  },
  computed: {
    isMobile() {
      return this.$store.state.responsive.isMobile;
    },
  },

};
</script>

<style scoped lang="scss">
@import "./../main.scss";

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh; 
  
}

.login-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
}

.login-page-button{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
  
}
form {
  margin: 1rem;
  padding: 1rem;
}
.form-mobile {
  padding: 1rem;
  margin: 0rem;
}

.form-control {
  margin: 0.5rem 0;
}

label {
  font-family: BwGradual;
  color: $main-colour-dark;
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}

input,
textarea {
  display: block;
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  padding: 0.15rem;
  background-color: $blue-green-light;
  margin-bottom: 2rem;
  margin-top: 1rem;
  border-radius: 15px
}

input:focus,
textarea:focus {
  border-color: $main-colour;
  color: $main-colour-dark;
 
}


.plant{
  max-width: 15rem;
}




.dark-overlay {
    position: relative;
  }

  .dark-overlay::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000; 
  }

  
  .element-to-remain-visible {
    position: relative;
    z-index: 1000; 
  }

  .not-valid-input {
    border-color: $error-colour;
    color: $error-colour;
  }

  .bottom-section{
    display: grid;
    grid-template-columns: auto auto;
  }

  .password-desc{
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }





</style>
