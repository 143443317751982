import api from "../api.js";

export default {
  
  async getData(context, payload) {
    const token = localStorage.getItem("token");
    try {
      const database = payload.database;
      let url = `${api}farm_data/dataFromInflux`;
      if (database === "Grafana") {
        url = `${api}farm_data/dataFromGrafana`;
      }
      if (database === "farmsId") {
        url = `${api}farm_data/getUserFarms`;
      }
      if (database === "cropTemplates") {
        url = `${api}control_panel/read_crop_templates`;
      }
      
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        context.commit(
          "dataError",
          "Błąd poczas odczytu danych. Dane mogą być nieaktualne",
        );
        const error = new Error(
          responseData.message ||
            "Failed to authenticate. Check your login data.",
        );
        throw error;
      } else {
        if (database === 'cropTemplates') {
          context.commit("dataError", "");
          context.commit("getCropTemplates", responseData);

        }else if (database === 'Grafana') {
          context.commit("dataError", "");
          context.commit("getGraphs", responseData);

        }else {
          context.commit("dataError", "");
          context.commit("getData", responseData);
        }
      }
    } catch {
      context.commit(
        "dataError",
        "Błąd poczas komunikacji z serwerem. Dane mogą być nieaktualne",
      );
    }
  },
  async setThresholds(context, payload) {
    const token = localStorage.getItem("token");
    try {
      let url = `${api}farm_data/add_remove_thresholds/${payload.panel}/${payload.folder}/${payload.value * 10}/${payload.uid}`;
 
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        context.commit(
          "dataError",
          "Błąd poczas odczytu danych. Dane mogą być nieaktualne",
        );
        const error = new Error(
          responseData.message ||
            "Failed to authenticate. Check your login data.",
        );
        throw error;
      } else {
      
        context.commit("dataError", "");
        context.commit("getData", responseData);
        
      }
    } catch {
      context.commit(
        "dataError",
        "Błąd poczas komunikacji z serwerem. Dane mogą być nieaktualne",
        
      );
    }
    return "ok"
  },

  async deleteThresholds(context, payload) {
    const token = localStorage.getItem("token");
    try {
      let url = `${api}farm_data/remove_thresholds/${payload.panel}/${payload.folder}/${payload.uid}`;
 
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        context.commit(
          "dataError",
          "Błąd poczas odczytu danych. Dane mogą być nieaktualne",
        );
        const error = new Error(
          responseData.message ||
            "Failed to authenticate. Check your login data.",
        );
        throw error;
      } else {
      
        context.commit("dataError", "");
        context.commit("getData", responseData);
        
      }
    } catch {
      context.commit(
        "dataError",
        "Błąd poczas komunikacji z serwerem. Dane mogą być nieaktualne",
        
      );
    }
    return "ok"
  },

  async updateData(context, payload) {
    try {
      let url = `${api}control_panel/updateRack`;
      const token = localStorage.getItem("token");
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        
      });
      const responseData = await response.json();
      if (!response.ok) {
        context.commit(
          "dataError",
          "Błąd poczas odczytu danych. Dane mogą być nieaktualne",
        );
        const error = new Error(
          responseData.message ||
            "Failed to authenticate. Check your login data.",
        );
        throw error;
      } else {
        

        
          context.commit("dataError", "");
          context.commit("getData", responseData);
        
      }
    } catch {
      context.commit(
        "dataError",
        "Błąd poczas komunikacji z serwerem.",
      );
    }
  },
  async sendData(context, payload) {
    try {
      let url = `${api}control_panel/create_crop_template`;
      const token = localStorage.getItem("token");
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        
      });
      const responseData = await response.json();
      if (!response.ok) {
        context.commit(
          "dataError",
          "Błąd poczas odczytu danych",
        );
        const error = new Error(
          responseData.message ||
            "Failed to authenticate.",
        );
        throw error;
      } else {
        

        
          context.commit("dataError", "");
          context.commit("getData", responseData);
        
      }
    } catch {
      context.commit(
        "dataError",
        "Błąd poczas komunikacji z serwerem.",
      );
    }
  },
  async deleteTemplate(context, {template, farmsStr}) {
    
    try {
      let url = `${api}control_panel/delete_crop_template/?template=${template}&farmsStr=${farmsStr}`;
      const token = localStorage.getItem("token");
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
       
        
        
      });
      const responseData = await response.json();
      
      if (!response.ok) {
        context.commit(
          "dataError",
          "Błąd poczas odczytu danych",
        );
        const error = new Error(
          responseData.message ||
            "Failed to authenticate.",
        );
        throw error;
      } else {
        

        
          context.commit("dataError", "");
          context.commit("getData", responseData);
        
      }
    } catch {
      context.commit(
        "dataError",
        "Błąd poczas komunikacji z serwerem.",
      );
    }
  },
};
