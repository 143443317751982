<template>
  <div class="card-login">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
@import "../../main.scss";
.card-login {
  border-radius: 30px;
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.26);
  padding: 0;
  background-color: $blue-green-light;
  width: 70%;
  min-height: 22rem;
  
}
</style>
