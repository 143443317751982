<template>
  <div class="mobile-menu">
    <router-link to="/" class="icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="#000000"
        viewBox="0 0 256 256"
      >
        <path
          d="M216,40H136V24a8,8,0,0,0-16,0V40H40A16,16,0,0,0,24,56V176a16,16,0,0,0,16,16H79.36L57.75,219a8,8,0,0,0,12.5,10l29.59-37h56.32l29.59,37a8,8,0,1,0,12.5-10l-21.61-27H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,136H40V56H216V176Z"
        ></path>
      </svg>
      <span>Informacje</span>
    </router-link>
    <router-link to="/details" class="icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="#000000"
        viewBox="0 0 256 256"
      >
        <path
          d="M216,40H136V24a8,8,0,0,0-16,0V40H40A16,16,0,0,0,24,56V176a16,16,0,0,0,16,16H79.36L57.75,219a8,8,0,0,0,12.5,10l29.59-37h56.32l29.59,37a8,8,0,1,0,12.5-10l-21.61-27H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,136H40V56H216V176ZM104,120v24a8,8,0,0,1-16,0V120a8,8,0,0,1,16,0Zm32-16v40a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm32-16v56a8,8,0,0,1-16,0V88a8,8,0,0,1,16,0Z"
        ></path>
      </svg>
      <span>Szczegóły</span>
    </router-link>
    <router-link v-if="userId != 7" to="/controls" class="icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="#000000"
        viewBox="0 0 256 256"
      >
        <path
          d="M176,56H80a72,72,0,0,0,0,144h96a72,72,0,0,0,0-144Zm0,128H80A56,56,0,0,1,80,72h96a56,56,0,0,1,0,112Zm0-96a40,40,0,1,0,40,40A40,40,0,0,0,176,88Zm0,64a24,24,0,1,1,24-24A24,24,0,0,1,176,152Z"
        ></path>
      </svg>
      <span>Sterowanie</span>
    </router-link>
    <router-link v-if="userId != 7" to="/logs" class="icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="#000000"
        viewBox="0 0 256 256"
      >
        <path
          d="M136,80v43.47l36.12,21.67a8,8,0,0,1-8.24,13.72l-40-24A8,8,0,0,1,120,128V80a8,8,0,0,1,16,0Zm-8-48A95.44,95.44,0,0,0,60.08,60.15C52.81,67.51,46.35,74.59,40,82V64a8,8,0,0,0-16,0v40a8,8,0,0,0,8,8H72a8,8,0,0,0,0-16H49c7.15-8.42,14.27-16.35,22.39-24.57a80,80,0,1,1,1.66,114.75,8,8,0,1,0-11,11.64A96,96,0,1,0,128,32Z"
        ></path>
      </svg>
      <span>Historia</span>
    </router-link>
    <div @click="logout" class="icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="#000000"
        viewBox="0 0 256 256"
      >
        <path
          d="M112,216a8,8,0,0,1-8,8H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32h56a8,8,0,0,1,0,16H48V208h56A8,8,0,0,1,112,216Zm109.66-93.66-40-40a8,8,0,0,0-11.32,11.32L196.69,120H104a8,8,0,0,0,0,16h92.69l-26.35,26.34a8,8,0,0,0,11.32,11.32l40-40A8,8,0,0,0,221.66,122.34Z"
        ></path>
      </svg>
      <span>Wyloguj</span>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      
    }
  },
  watch: {
    userId: function(newUserId) {
      
      this.userId = newUserId
    },
  },
methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/login");
    },
  },
  computed: {
    userId() {
      
      return this.$store.getters.userId;
      
    },
  }
}
</script>

<style scoped lang="scss">
@import "./../../main.scss";

.mobile-menu {
  background-color: rgba(251, 248, 244, 0.95);
  padding-top: 0.5rem;
  width: 100%;
  height: 4.5rem;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid $main-colour-dark;
  
  
  svg {
    fill: $main-colour-dark;
  }
}
.icon {
  display: flex;
  flex-direction: column;
  max-width: 3rem;
  justify-content: center;
  align-items: center;
  color: $main-colour-dark;
  cursor: pointer;
}


a {
  text-decoration: none;
  color: $main-colour-dark;
  display: inline-block;

}

a:active,
a:hover,
a.router-link-active {
  font-weight: bold;
}
</style>
