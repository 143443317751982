<template>
  <div>
    <div class="group">
      <h5 >Przejście 41</h5>
      <div class="rack-border"></div>
      <div :class="{'szklarnia-mobile-grid': isMobile, 'szklarnia-grid': !isMobile}">
      
        <div>
          <p class="co2">Stężenie CO2:</p> <p class="value"> {{ average(mesurements['41']['co2']['20cm']) }} ppm </p>
          <p class="co2">EC maty</p> <p class="value"> {{ (average(mesurements['41']['mat_ec']) * 0.001).toFixed(2) }}  mS/cm</p>
          <p class="co2">Temperatura maty:</p> <p class="value"> {{ (average(mesurements['41']['mat_temperature'])).toFixed(1) }} &deg;C </p>
          <p></p>
        </div>
        
        <grid-card>  
          <div >
            <h5 class="cat-header">Temperatura:</h5>
            <div class="cat-border"></div>
          </div>
          <div>
            <p>&nbsp;&nbsp;20cm:<span class="value">{{ average(mesurements['41']['temperature']['20cm']) }} &deg;C</span></p>
            <p>100cm:<span class="value"> {{ average(mesurements['41']['temperature']['100cm']) }} &deg;C</span></p>
            <p>200cm:<span class="value">{{ average(mesurements['41']['temperature']['200cm']) }} &deg;C</span> </p>
            <p>300cm:<span class="value">{{ average(mesurements['41']['temperature']['300cm']) }} &deg;C</span> </p>
            </div>
        </grid-card>

        <grid-card>
          <div >
            <h5 class="cat-header">Wilgotność:</h5>
            <div class="cat-border"></div>
          </div>
          <div>
            <p>&nbsp;&nbsp;20cm:<span class="value">{{ average(mesurements['41']['humidity']['20cm']) }} %Rh</span></p>
            <p>100cm:<span class="value"> {{ average(mesurements['41']['humidity']['100cm']) }} %Rh</span></p>
            <p>200cm:<span class="value">{{ average(mesurements['41']['humidity']['200cm']) }} %Rh</span> </p>
            <p>300cm:<span class="value">{{ average(mesurements['41']['humidity']['300cm']) }} %Rh</span> </p>
            </div>
        </grid-card>

        <grid-card>
          <div >
            <h5 class="cat-header">Światło:</h5>
            <div class="cat-border"></div>
          </div>
          <div>
            <p>&nbsp;&nbsp;20cm:<span class="value">{{ average(mesurements['41']['light']['20cm']) }} lux</span></p>
            <p>100cm:<span class="value"> {{ average(mesurements['41']['light']['100cm']) }} lux</span></p>
            <p>200cm:<span class="value">{{ average(mesurements['41']['light']['200cm']) }} lux</span> </p>
            <p>300cm:<span class="value">{{ average(mesurements['41']['light']['300cm']) }} lux</span> </p>
            </div>
        </grid-card>
    </div>
  </div>
  <div class="group">
    <h5 >Przejście 65</h5>
    <div class="rack-border"></div>
      <div :class="{'szklarnia-mobile-grid': isMobile, 'szklarnia-grid': !isMobile}">
      
        <div>
          <p class="co2">Stężenie CO2:</p> <p class="value"> {{ average(mesurements['65']['co2']['20cm']) }} ppm </p>
          <p></p>
        </div>
        
        <grid-card>  
          <div >
            <h5 class="cat-header">Temperatura:</h5>
            <div class="cat-border"></div>
          </div>
          <div>
            <p>&nbsp;&nbsp;20cm:<span class="value">{{ average(mesurements['65']['temperature']['20cm']) }} &deg;C</span></p>
            <p>100cm:<span class="value"> {{ average(mesurements['65']['temperature']['100cm']) }} &deg;C</span></p>
            <p>200cm:<span class="value">{{ average(mesurements['65']['temperature']['200cm']) }} &deg;C</span> </p>
            <p>300cm:<span class="value">{{ average(mesurements['65']['temperature']['300cm']) }} &deg;C</span> </p>
            </div>
        </grid-card>

        <grid-card>
          <div >
            <h5 class="cat-header">Wilgotność:</h5>
            <div class="cat-border"></div>
          </div>
          <div>
            <p>&nbsp;&nbsp;20cm:<span class="value">{{ average(mesurements['65']['humidity']['20cm']) }} %Rh</span></p>
            <p>100cm:<span class="value"> {{ average(mesurements['65']['humidity']['100cm']) }} %Rh</span></p>
            <p>200cm:<span class="value">{{ average(mesurements['65']['humidity']['200cm']) }} %Rh</span> </p>
            <p>300cm:<span class="value">{{ average(mesurements['65']['humidity']['300cm']) }} %Rh</span> </p>
            </div>
        </grid-card>

        <grid-card>
          <div >
            <h5 class="cat-header">Światło:</h5>
            <div class="cat-border"></div>
          </div>
          <div>
            <p>&nbsp;&nbsp;20cm:<span class="value">{{ average(mesurements['65']['light']['20cm']) }} lux</span></p>
            <p>100cm:<span class="value"> {{ average(mesurements['65']['light']['100cm']) }} lux</span></p>
            <p>200cm:<span class="value">{{ average(mesurements['65']['light']['200cm']) }} lux</span> </p>
            <p>300cm:<span class="value">{{ average(mesurements['65']['light']['300cm']) }} lux</span> </p>
            </div>
        </grid-card>
    </div>
  </div>

  <div class="group" v-if="average(mesurements['105']['co2']['20cm'])">
    <h5 >Przejście 105</h5>
      <div class="rack-border"></div>
      <div :class="{'szklarnia-mobile-grid': isMobile, 'szklarnia-grid': !isMobile}">
      
        <div>
          <p class="co2">Stężenie CO2:</p> <p class="value"> {{ average(mesurements['41']['co2']['20cm']) }} ppm </p>
          <p></p>
        </div>
        
        <grid-card>  
          <div >
            <h5 class="cat-header">Temperatura:</h5>
            <div class="cat-border"></div>
          </div>
          <div>
            <p>&nbsp;&nbsp;20cm:<span class="value">{{ average(mesurements['105']['temperature']['20cm']) }} &deg;C</span></p>
            <p>100cm:<span class="value"> {{ average(mesurements['105']['temperature']['100cm']) }} &deg;C</span></p>
            <p>200cm:<span class="value">{{ average(mesurements['105']['temperature']['200cm']) }} &deg;C</span> </p>
            <p>300cm:<span class="value">{{ average(mesurements['105']['temperature']['300cm']) }} &deg;C</span> </p>
            </div>
        </grid-card>

        <grid-card>
          <div >
            <h5 class="cat-header">Wilgotność:</h5>
            <div class="cat-border"></div>
          </div>
          <div>
            <p>&nbsp;&nbsp;20cm:<span class="value">{{ average(mesurements['105']['humidity']['20cm']) }} %Rh</span></p>
            <p>100cm:<span class="value"> {{ average(mesurements['105']['humidity']['100cm']) }} %Rh</span></p>
            <p>200cm:<span class="value">{{ average(mesurements['105']['humidity']['200cm']) }} %Rh</span> </p>
            <p>300cm:<span class="value">{{ average(mesurements['105']['humidity']['300cm']) }} %Rh</span> </p>
            </div>
        </grid-card>

        <grid-card>
          <div >
            <h5 class="cat-header">Światło:</h5>
            <div class="cat-border"></div>
          </div>
          <div>
            <p>&nbsp;&nbsp;20cm:<span class="value">{{ average(mesurements['105']['light']['20cm']) }} lux</span></p>
            <p>100cm:<span class="value"> {{ average(mesurements['105']['light']['100cm']) }} lux</span></p>
            <p>200cm:<span class="value">{{ average(mesurements['105']['light']['200cm']) }} lux</span> </p>
            <p>300cm:<span class="value">{{ average(mesurements['105']['light']['300cm']) }} lux</span> </p>
            </div>
        </grid-card>
    </div>
  </div>


  <div class="group" v-if="average(mesurements['117']['co2']['20cm'])">
    <h5 >Przejście 117</h5>
      <div class="rack-border"></div>
      <div :class="{'szklarnia-mobile-grid': isMobile, 'szklarnia-grid': !isMobile}">
      
        <div>
          <p class="co2">Stężenie CO2:</p> <p class="value"> {{ average(mesurements['41']['co2']['20cm']) }} ppm </p>
          <p class="co2">EC maty</p> <p class="value"> {{ (average(mesurements['41']['mat_ec']) * 0.001).toFixed(2) }}  mS/cm</p>
          <p class="co2">Temperatura maty:</p> <p class="value"> {{ (average(mesurements['41']['mat_temperature'])).toFixed(1) }} &deg;C </p>
          <p></p>
        </div>
        
        <grid-card>  
          <div >
            <h5 class="cat-header">Temperatura:</h5>
            <div class="cat-border"></div>
          </div>
          <div>
            <p>&nbsp;&nbsp;20cm:<span class="value">{{ average(mesurements['117']['temperature']['20cm']) }} &deg;C</span></p>
            <p>100cm:<span class="value"> {{ average(mesurements['117']['temperature']['100cm']) }} &deg;C</span></p>
            <p>200cm:<span class="value">{{ average(mesurements['117']['temperature']['200cm']) }} &deg;C</span> </p>
            <p>300cm:<span class="value">{{ average(mesurements['117']['temperature']['300cm']) }} &deg;C</span> </p>
            </div>
        </grid-card>

        <grid-card>
          <div >
            <h5 class="cat-header">Wilgotność:</h5>
            <div class="cat-border"></div>
          </div>
          <div>
            <p>&nbsp;&nbsp;20cm:<span class="value">{{ average(mesurements['117']['humidity']['20cm']) }} %Rh</span></p>
            <p>100cm:<span class="value"> {{ average(mesurements['117']['humidity']['100cm']) }} %Rh</span></p>
            <p>200cm:<span class="value">{{ average(mesurements['117']['humidity']['200cm']) }} %Rh</span> </p>
            <p>300cm:<span class="value">{{ average(mesurements['117']['humidity']['300cm']) }} %Rh</span> </p>
            </div>
        </grid-card>

        <grid-card>
          <div >
            <h5 class="cat-header">Światło:</h5>
            <div class="cat-border"></div>
          </div>
          <div>
            <p>&nbsp;&nbsp;20cm:<span class="value">{{ average(mesurements['117']['light']['20cm']) }} lux</span></p>
            <p>100cm:<span class="value"> {{ average(mesurements['117']['light']['100cm']) }} lux</span></p>
            <p>200cm:<span class="value">{{ average(mesurements['117']['light']['200cm']) }} lux</span> </p>
            <p>300cm:<span class="value">{{ average(mesurements['117']['light']['300cm']) }} lux</span> </p>
            </div>
        </grid-card>
    </div>
  </div>

  <div class="group" v-if="average(mesurements['145']['co2']['20cm'])">
    <h5 >Przejście 145</h5>
    <div class="rack-border"></div>
      <div :class="{'szklarnia-mobile-grid': isMobile, 'szklarnia-grid': !isMobile}">
      
        <div>
          <p class="co2">Stężenie CO2:</p> <p class="value"> {{ average(mesurements['145']['co2']['20cm']) }} ppm </p>
          <p></p>
        </div>
        
        <grid-card>  
          <div >
            <h5 class="cat-header">Temperatura:</h5>
            <div class="cat-border"></div>
          </div>
          <div>
            <p>&nbsp;&nbsp;20cm:<span class="value">{{ average(mesurements['145']['temperature']['20cm']) }} &deg;C</span></p>
            <p>100cm:<span class="value"> {{ average(mesurements['145']['temperature']['100cm']) }} &deg;C</span></p>
            <p>200cm:<span class="value">{{ average(mesurements['145']['temperature']['200cm']) }} &deg;C</span> </p>
            <p>300cm:<span class="value">{{ average(mesurements['145']['temperature']['300cm']) }} &deg;C</span> </p>
            </div>
        </grid-card>

        <grid-card>
          <div >
            <h5 class="cat-header">Wilgotność:</h5>
            <div class="cat-border"></div>
          </div>
          <div>
            <p>&nbsp;&nbsp;20cm:<span class="value">{{ average(mesurements['145']['humidity']['20cm']) }} %Rh</span></p>
            <p>100cm:<span class="value"> {{ average(mesurements['145']['humidity']['100cm']) }} %Rh</span></p>
            <p>200cm:<span class="value">{{ average(mesurements['145']['humidity']['200cm']) }} %Rh</span> </p>
            <p>300cm:<span class="value">{{ average(mesurements['145']['humidity']['300cm']) }} %Rh</span> </p>
            </div>
        </grid-card>

        <grid-card>
          <div >
            <h5 class="cat-header">Światło:</h5>
            <div class="cat-border"></div>
          </div>
          <div>
            <p>&nbsp;&nbsp;20cm:<span class="value">{{ average(mesurements['145']['light']['20cm']) }} lux</span></p>
            <p>100cm:<span class="value"> {{ average(mesurements['145']['light']['100cm']) }} lux</span></p>
            <p>200cm:<span class="value">{{ average(mesurements['145']['light']['200cm']) }} lux</span> </p>
            <p>300cm:<span class="value">{{ average(mesurements['145']['light']['300cm']) }} lux</span> </p>
            </div>
        </grid-card>
    </div>
    </div>

    <div class="group" v-if="average(mesurements['cucumbers']['co2']['20cm'])">
    <h5 >Ogórki</h5>
    <div class="rack-border"></div>
      <div :class="{'szklarnia-mobile-grid': isMobile, 'szklarnia-grid': !isMobile}">
      
        <div>
          <p class="co2">Stężenie CO2:</p> <p class="value"> {{ average(mesurements['cucumbers']['co2']['20cm']) }} ppm </p>
          <p class="co2">EC maty</p> <p class="value"> {{ (average(mesurements['cucumbers']['mat_ec'])* 0.001).toFixed(2) }}  mS/cm</p>
          <p class="co2">Temperatura maty:</p> <p class="value"> {{ (average(mesurements['cucumbers']['mat_temperature']).toFixed(1)) }} &deg;C </p>
          <p></p>
        </div>
        
        <grid-card>  
          <div >
            <h5 class="cat-header">Temperatura:</h5>
            <div class="cat-border"></div>
          </div>
          <div>
            <p>&nbsp;&nbsp;20cm:<span class="value">{{ average(mesurements['cucumbers']['temperature']['20cm']) }} &deg;C</span></p>
            <p>100cm:<span class="value"> {{ average(mesurements['cucumbers']['temperature']['100cm']) }} &deg;C</span></p>
            <p>200cm:<span class="value">{{ average(mesurements['cucumbers']['temperature']['200cm']) }} &deg;C</span> </p>
            <p>300cm:<span class="value">{{ average(mesurements['cucumbers']['temperature']['300cm']) }} &deg;C</span> </p>
            </div>
        </grid-card>

        <grid-card>
          <div >
            <h5 class="cat-header">Wilgotność:</h5>
            <div class="cat-border"></div>
          </div>
          <div>
            <p>&nbsp;&nbsp;20cm:<span class="value">{{ average(mesurements['cucumbers']['humidity']['20cm']) }} %Rh</span></p>
            <p>100cm:<span class="value"> {{ average(mesurements['cucumbers']['humidity']['100cm']) }} %Rh</span></p>
            <p>200cm:<span class="value">{{ average(mesurements['cucumbers']['humidity']['200cm']) }} %Rh</span> </p>
            <p>300cm:<span class="value">{{ average(mesurements['cucumbers']['humidity']['300cm']) }} %Rh</span> </p>
            </div>
        </grid-card>

        <grid-card>
          <div >
            <h5 class="cat-header">Światło:</h5>
            <div class="cat-border"></div>
          </div>
          <div>
            <p>&nbsp;&nbsp;20cm:<span class="value">{{ average(mesurements['cucumbers']['light']['20cm']) }} lux</span></p>
            <p>100cm:<span class="value"> {{ average(mesurements['cucumbers']['light']['100cm']) }} lux</span></p>
            <p>200cm:<span class="value">{{ average(mesurements['cucumbers']['light']['200cm']) }} lux</span> </p>
            <p>300cm:<span class="value">{{ average(mesurements['cucumbers']['light']['300cm']) }} lux</span> </p>
            </div>
        </grid-card>
    </div>
    </div>
  </div>
</template>

<script>

import { format, utcToZonedTime } from "date-fns-tz";
export default {
 
  data() {
    return {
      
      trendUp: require("@/assets/icons/TrendUp.svg"),
      trendDown: require("@/assets/icons/TrendDown.svg"),
      userId: localStorage.getItem('userId') || '',
      
        
      nameMap: {
        scd41_co2ppm: "Stężenie CO2",
        scd41_humidity: "Wilgotność",
        scd41_temperature: "Temperatura",
        sht45_humidity: "Wilgotność",
        sht45_temperature: "Temperatura",
        veml7700_white: "Światło",
      },
      
      mesurements:{
        '41': {
          mat_ec:[],
          mat_temperature: [],
          co2: {
            '20cm': [],
            '100cm': [],
            '200cm': [],
            '300cm': []
          },
          humidity: {
            '20cm': [],
            '100cm': [],
            '200cm': [],
            '300cm': []
          },
          temperature: {
            '20cm': [],
            '100cm': [],
            '200cm': [],
            '300cm': []
          },
          light: {
            '20cm': [],
            '100cm': [],
            '200cm': [],
            '300cm': []
          }
        },
        '65': {
          co2: {
            '20cm': [],
            '100cm': [],
            '200cm': [],
            '300cm': []
          },
          humidity: {
            '20cm': [],
            '100cm': [],
            '200cm': [],
            '300cm': []
          },
          temperature: {
            '20cm': [],
            '100cm': [],
            '200cm': [],
            '300cm': []
          },
          light: {
            '20cm': [],
            '100cm': [],
            '200cm': [],
            '300cm': []
          }
        },
        
        '105': {
          co2: {
            '20cm': [],
            '100cm': [],
            '200cm': [],
            '300cm': []
          },
          humidity: {
            '20cm': [],
            '100cm': [],
            '200cm': [],
            '300cm': []
          },
          temperature: {
            '20cm': [],
            '100cm': [],
            '200cm': [],
            '300cm': []
          },
          light: {
            '20cm': [],
            '100cm': [],
            '200cm': [],
            '300cm': []
          }
        },
        '117': {
          mat_ec:[],
          mat_temperature: [],
          co2: {
            '20cm': [],
            '100cm': [],
            '200cm': [],
            '300cm': []
          },
          humidity: {
            '20cm': [],
            '100cm': [],
            '200cm': [],
            '300cm': []
          },
          temperature: {
            '20cm': [],
            '100cm': [],
            '200cm': [],
            '300cm': []
          },
          light: {
            '20cm': [],
            '100cm': [],
            '200cm': [],
            '300cm': []
          }
        },
        '145': {
          co2: {
            '20cm': [],
            '100cm': [],
            '200cm': [],
            '300cm': []
          },
          humidity: {
            '20cm': [],
            '100cm': [],
            '200cm': [],
            '300cm': []
          },
          temperature: {
            '20cm': [],
            '100cm': [],
            '200cm': [],
            '300cm': []
          },
          light: {
            '20cm': [],
            '100cm': [],
            '200cm': [],
            '300cm': []
          }
        },
        'cucumbers': {
          mat_ec:[],
          mat_temperature: [],
          co2: {
            '20cm': [],
            '100cm': [],
            '200cm': [],
            '300cm': []
          },
          humidity: {
            '20cm': [],
            '100cm': [],
            '200cm': [],
            '300cm': []
          },
          temperature: {
            '20cm': [],
            '100cm': [],
            '200cm': [],
            '300cm': []
          },
          light: {
            '20cm': [],
            '100cm': [],
            '200cm': [],
            '300cm': []
          }
        },
      },
      currentHeight: "",
      
      
    }
  },
  props: {
    farm: {
      type: Object,
      required: false,
    },
    showNotImportant: {
      type: Boolean,
      required: false,
      default: false,
    },
    valueToShow:{
     type: Array, 
    },
    farmName: {
      type: String
    }
  },

  watch: {
    farm: {
      handler(newVal) {
        for (const [key, values] of Object.entries(newVal)) {
          if (key.slice(0, 4) === 'N003'){
            for (const [name, value] of Object.entries(values)){
              if (name === 'scd41_co2ppm') {

                
                if(key.slice(-3) === '001'){
                  this.mesurements['41']['co2']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['41']['co2']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['41']['co2']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['41']['co2']['300cm'].push(value.value)
                }


              }else if(name === 'scd41_humidity' || name === 'sht45_humidity'){

                if(key.slice(-3) === '001'){
                  this.mesurements['41']['humidity']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['41']['humidity']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['41']['humidity']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['41']['humidity']['300cm'].push(value.value)
                }

              }else if(name === 'scd41_temperature' || name === 'sht45_temperature'){

                if(key.slice(-3) === '001'){
                  this.mesurements['41']['temperature']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['41']['temperature']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['41']['temperature']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['41']['temperature']['300cm'].push(value.value)
                }

              }else if(name === 'veml7700_white'){

                if(key.slice(-3) === '001'){
                  this.mesurements['41']['light']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['41']['light']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['41']['light']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['41']['light']['300cm'].push(value.value)
                }

              }
            }         
          }else if (key.slice(0, 4) === 'N001'){
            for (const [name, value] of Object.entries(values)){
              if (name === 'scd41_co2ppm') {

                
                if(key.slice(-3) === '001'){
                  this.mesurements['65']['co2']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['65']['co2']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['65']['co2']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['65']['co2']['300cm'].push(value.value)
                }


              }else if(name === 'scd41_humidity' || name === 'sht45_humidity'){

                if(key.slice(-3) === '001'){
                  this.mesurements['65']['humidity']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['65']['humidity']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['65']['humidity']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['65']['humidity']['300cm'].push(value.value)
                }

              }else if(name === 'scd41_temperature' || name === 'sht45_temperature'){

                if(key.slice(-3) === '001'){
                  this.mesurements['65']['temperature']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['65']['temperature']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['65']['temperature']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['65']['temperature']['300cm'].push(value.value)
                }

              }else if(name === 'veml7700_white'){

                if(key.slice(-3) === '001'){
                  this.mesurements['65']['light']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['65']['light']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['65']['light']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['65']['light']['300cm'].push(value.value)
                }

              }
            }         
          }else if (key.slice(0, 4) === 'N005'){
            for (const [name, value] of Object.entries(values)){
              if (name === 'scd41_co2ppm') {

                
                if(key.slice(-3) === '001'){
                  this.mesurements['105']['co2']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['105']['co2']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['105']['co2']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['105']['co2']['300cm'].push(value.value)
                }


              }else if(name === 'scd41_humidity' || name === 'sht45_humidity'){

                if(key.slice(-3) === '001'){
                  this.mesurements['105']['humidity']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['41']['humidity']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['105']['humidity']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['105']['humidity']['300cm'].push(value.value)
                }

              }else if(name === 'scd41_temperature' || name === 'sht45_temperature'){

                if(key.slice(-3) === '001'){
                  this.mesurements['105']['temperature']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['105']['temperature']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['105']['temperature']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['105']['temperature']['300cm'].push(value.value)
                }

              }else if(name === 'veml7700_white'){

                if(key.slice(-3) === '001'){
                  this.mesurements['105']['light']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['105']['light']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['105']['light']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['105']['light']['300cm'].push(value.value)
                }

              }
            }         
          }else if (key.slice(0, 4) === 'N002'){
            for (const [name, value] of Object.entries(values)){
              if (name === 'scd41_co2ppm') {

                
                if(key.slice(-3) === '001'){
                  this.mesurements['145']['co2']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['145']['co2']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['145']['co2']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['145']['co2']['300cm'].push(value.value)
                }


              }else if(name === 'scd41_humidity' || name === 'sht45_humidity'){

                if(key.slice(-3) === '001'){
                  this.mesurements['145']['humidity']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['145']['humidity']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['145']['humidity']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['145']['humidity']['300cm'].push(value.value)
                }

              }else if(name === 'scd41_temperature' || name === 'sht45_temperature'){

                if(key.slice(-3) === '001'){
                  this.mesurements['145']['temperature']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['145']['temperature']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['145']['temperature']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['145']['temperature']['300cm'].push(value.value)
                }

              }else if(name === 'veml7700_white'){

                if(key.slice(-3) === '001'){
                  this.mesurements['145']['light']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['145']['light']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['145']['light']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['145']['light']['300cm'].push(value.value)
                }

              }
            }           
          }else if (key.slice(0, 4) === 'N007'){
            for (const [name, value] of Object.entries(values)){


              
              if (name === 'teros12_ec') {
                this.mesurements['41']['mat_ec'].push(value.value)
              }else if (name === 'teros12_temperature') {
                this.mesurements['41']['mat_temperature'].push(value.value)
              }


              else if (name === 'scd41_co2ppm') {

                
                if(key.slice(-3) === '001'){
                  this.mesurements['41']['co2']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['41']['co2']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['41']['co2']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['41']['co2']['300cm'].push(value.value)
                }


              }else if(name === 'scd41_humidity' || name === 'sht45_humidity'){

                if(key.slice(-3) === '001'){
                  this.mesurements['41']['humidity']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['41']['humidity']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['41']['humidity']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['41']['humidity']['300cm'].push(value.value)
                }

              }else if(name === 'scd41_temperature' || name === 'sht45_temperature'){

                if(key.slice(-3) === '001'){
                  this.mesurements['41']['temperature']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['41']['temperature']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['41']['temperature']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['41']['temperature']['300cm'].push(value.value)
                }

              }else if(name === 'veml7700_white'){

                if(key.slice(-3) === '001'){
                  this.mesurements['41']['light']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['41']['light']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['41']['light']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['41']['light']['300cm'].push(value.value)
                }

              }
            }             
          }else if (key.slice(0, 4) === 'N006'){
            for (const [name, value] of Object.entries(values)){
              if (name === 'scd41_co2ppm') {

                
                if(key.slice(-3) === '001'){
                  this.mesurements['65']['co2']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['65']['co2']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['65']['co2']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['65']['co2']['300cm'].push(value.value)
                }


              }else if(name === 'scd41_humidity' || name === 'sht45_humidity'){

                if(key.slice(-3) === '001'){
                  this.mesurements['65']['humidity']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['65']['humidity']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['41']['humidity']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['65']['humidity']['300cm'].push(value.value)
                }

              }else if(name === 'scd41_temperature' || name === 'sht45_temperature'){

                if(key.slice(-3) === '001'){
                  this.mesurements['65']['temperature']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['65']['temperature']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['65']['temperature']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['65']['temperature']['300cm'].push(value.value)
                }

              }else if(name === 'veml7700_white'){

                if(key.slice(-3) === '001'){
                  this.mesurements['65']['light']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['65']['light']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['65']['light']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['65']['light']['300cm'].push(value.value)
                }

              }
            }           
          }else if (key.slice(0, 4) === 'N008'){
            for (const [name, value] of Object.entries(values)){
              if (name === 'scd41_co2ppm') {

                
                if(key.slice(-3) === '001'){
                  this.mesurements['105']['co2']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['105']['co2']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['105']['co2']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['105']['co2']['300cm'].push(value.value)
                }


              }else if(name === 'scd41_humidity' || name === 'sht45_humidity'){

                if(key.slice(-3) === '001'){
                  this.mesurements['105']['humidity']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['105']['humidity']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['105']['humidity']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['105']['humidity']['300cm'].push(value.value)
                }

              }else if(name === 'scd41_temperature' || name === 'sht45_temperature'){

                if(key.slice(-3) === '001'){
                  this.mesurements['105']['temperature']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['105']['temperature']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['105']['temperature']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['105']['temperature']['300cm'].push(value.value)
                }

              }else if(name === 'veml7700_white'){

                if(key.slice(-3) === '001'){
                  this.mesurements['105']['light']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['105']['light']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['105']['light']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['105']['light']['300cm'].push(value.value)
                }

              }
            }                  
          }else if (key.slice(0, 4) === 'N011'){
            for (const [name, value] of Object.entries(values)){
              if (name === 'scd41_co2ppm') {

                
                if(key.slice(-3) === '001'){
                  this.mesurements['145']['co2']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['145']['co2']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['145']['co2']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['145']['co2']['300cm'].push(value.value)
                }


              }else if(name === 'scd41_humidity' || name === 'sht45_humidity'){

                if(key.slice(-3) === '001'){
                  this.mesurements['145']['humidity']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['145']['humidity']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['145']['humidity']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['145']['humidity']['300cm'].push(value.value)
                }

              }else if(name === 'scd41_temperature' || name === 'sht45_temperature'){

                if(key.slice(-3) === '001'){
                  this.mesurements['145']['temperature']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['145']['temperature']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['145']['temperature']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['145']['temperature']['300cm'].push(value.value)
                }

              }else if(name === 'veml7700_white'){

                if(key.slice(-3) === '001'){
                  this.mesurements['145']['light']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['145']['light']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['145']['light']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['145']['light']['300cm'].push(value.value)
                }

              }
            }                  
          }else if (key.slice(0, 4) === 'N009'){
            for (const [name, value] of Object.entries(values)){

              if (name === 'teros12_ec') {
                this.mesurements['cucumbers']['mat_ec'].push(value.value)
              }else if (name === 'teros12_temperature') {
                this.mesurements['cucumbers']['mat_temperature'].push(value.value)
              }


              else if (name === 'scd41_co2ppm') {

                
                if(key.slice(-3) === '001'){
                  this.mesurements['cucumbers']['co2']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['cucumbers']['co2']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['cucumbers']['co2']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['cucumbers']['co2']['300cm'].push(value.value)
                }


              }else if(name === 'scd41_humidity' || name === 'sht45_humidity'){

                if(key.slice(-3) === '001'){
                  this.mesurements['cucumbers']['humidity']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['cucumbers']['humidity']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['cucumbers']['humidity']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['cucumbers']['humidity']['300cm'].push(value.value)
                }

              }else if(name === 'scd41_temperature' || name === 'sht45_temperature'){

                if(key.slice(-3) === '001'){
                  this.mesurements['cucumbers']['temperature']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['cucumbers']['temperature']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['cucumbers']['temperature']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['cucumbers']['temperature']['300cm'].push(value.value)
                }

              }else if(name === 'veml7700_white'){

                if(key.slice(-3) === '001'){
                  this.mesurements['cucumbers']['light']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['cucumbers']['light']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['cucumbers']['light']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['cucumbers']['light']['300cm'].push(value.value)
                }

              }
            }                  
          }else if (key.slice(0, 4) === 'N004'){
            for (const [name, value] of Object.entries(values)){

              if (name === 'teros12_ec') {
                this.mesurements['117']['mat_ec'].push(value.value)
              }else if (name === 'teros12_temperature') {
                this.mesurements['117']['mat_temperature'].push(value.value)
              }


              else if (name === 'scd41_co2ppm') {

                
                if(key.slice(-3) === '001'){
                  this.mesurements['117']['co2']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['117']['co2']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['117']['co2']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['117']['co2']['300cm'].push(value.value)
                }


              }else if(name === 'scd41_humidity' || name === 'sht45_humidity'){

                if(key.slice(-3) === '001'){
                  this.mesurements['117']['humidity']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['117']['humidity']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['117']['humidity']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['117']['humidity']['300cm'].push(value.value)
                }

              }else if(name === 'scd41_temperature' || name === 'sht45_temperature'){

                if(key.slice(-3) === '001'){
                  this.mesurements['117']['temperature']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['117']['temperature']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['117']['temperature']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['117']['temperature']['300cm'].push(value.value)
                }

              }else if(name === 'veml7700_white'){

                if(key.slice(-3) === '001'){
                  this.mesurements['117']['light']['20cm'].push(value.value)
                }else if(key.slice(-3) === '002'){
                  this.mesurements['117']['light']['100cm'].push(value.value)
                }else if(key.slice(-3) === '003'){
                  this.mesurements['117']['light']['200cm'].push(value.value)
                }else if(key.slice(-3) === '004'){
                  this.mesurements['117']['light']['300cm'].push(value.value)
                }

              }
            }                  
          }
        } 
      },
    deep: true,
    immediate: true
  }
  },
  methods: {

    average(arr){
      let sum = arr.reduce((akumulator, currentVal) => akumulator + currentVal, 0);
      return Math.round(sum / arr.length * 10) / 10;
    },

    nodeHeight(node){
      if (node.slice(-3) === "001" ){
        return "20cm"
      }else if (node.slice(-3) === "002" ){      
        return "100cm"
      }else if (node.slice(-3) === "003" ){    
        return "200cm"
      }else if (node.slice(-3) === "004" ){
        return "300cm"
      }
    },

    
    
    isInImportantValues(group, value) {

      if (this.farmName == 'Szklarnia 6'){
        return true
      }
      
      if (!group || !value || !this.importantValues || !this.importantValues[group]) {
        return false;
      }

      

    return this.importantValues[group].includes(value);
},


    changeName(name) {

      const newName = this.nameMap[name] || name;

      return newName;
      },
    timeFormater(time) {
      const originalDate = time;
      const timeZone = this.timeZone;
      const zonedDate = utcToZonedTime(originalDate, timeZone);


      return format(zonedDate, "HH:mm", { timeZone });
    },
  },
  computed: {
    isMobile() {
      return this.$store.state.responsive.isMobile;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/main.scss";

.group{
  margin-bottom: 5rem;
}

.node-group{
  margin-top: 1rem;
}

.colum-and-names{
  display: grid;
  grid-template-columns: 4rem auto;
}

.height{

}

p{
  margin-left: 1rem;
}
h5{
  margin-top: 2rem;
  text-transform: uppercase;
  font-family: BwGradualnormal;
}

.category-name{
  margin-bottom: 2rem;
}

.teble-header{
  font-size: 1.2rem; 
  margin-top: 0.8rem;
}

.posiotnin{
  font-family: BwGradual;
  color: $main-colour-dark;
  font-size: 1.2rem;
  margin-left: 1rem;
  margin-top: 0.8rem;
  margin-bottom: 0.5rem;
}

.mesurement{
  font-family: BwGradual;
  color: $main-colour-dark;
  font-size: 1.2rem;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
}

.szklarnia-grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.szklarnia-mobile-grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.info-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  margin: 0;
  
}

.info-card-container-mobile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin: 0;
  
}
.trend-arrow {
  width: 1rem;
}
.light{
  width: 1rem;
  margin: auto;
}

.parametr {
  margin: 0.5rem 1rem;
  font-size: 1rem;
  font-family: BwGradualnormal;
  color: $main-colour-dark;
}

.value{
  font-family: BwGradual;
  color: $main-colour-dark;
  font-size: 1.2rem;
  margin-left: 1rem;
  margin-top: -0.8rem;
}

.rack-border{
  background-color: $blue-green;
  width: 18rem;
  height: 1px;
  margin-bottom: 3rem;
}

.cat-header{
  margin-left: 1.5rem;
  margin-bottom: 0.5rem;
}

.cat-border{
  width: 60%;
  height: 2px;
  background-color: $main-colour-dark;
  margin-left: 1rem;
  margin-bottom: 1rem;
}
.trend-arrow {
  width: 1rem;
}

.rack {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0;
  margin-bottom: 4rem;
}

.section-header {
  grid-column: 1 / -1;
}


.gray-background {
  background-color: $main-colour-light;
}

.cell {
  margin: 0.5rem 0;
}

.cell-header {
  margin: 0.5rem 0;
  span {
    margin-left: 1rem;
    font-weight: bold;
  }
}



.parametr {
  margin: 0.5rem 1rem;
  font-size: 1rem;
  font-family: BwGradualnormal;
  color: $main-colour-dark;
}

.value{
  font-family: BwGradual;
  color: $main-colour-dark;
  font-size: 1.2rem;
  margin-left: 1rem;
  margin-top: -0.8rem;
}

.position-rack{
  font-family: BwGradual;
  color: $main-colour-dark;
  font-size: 1.2rem;
  margin-left: 1rem;
  margin-top: 0.8rem;
}

.no-visible{
  color: $main-colour-light;
}

.co2{
  margin-top: 0.5rem;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
</style>
