<template>
  
    <div class="rack-header">
      <div>
        <h5>{{ changeName(rackName) }}</h5>
        <div class="rack-border"></div>
      </div>
      <div class="rack">
        <div
          class="column"
          v-for="(data, parametr, index) in dataToShow"
          :key="index"
        >
        
          <p class="position-rack">{{ checkLeftRight(parametr) }}</p>
          <div
            class="cell"
            
            v-for="(mesurement, parametrName, index) in data"
            :key="index"
          >
          
            <div v-if="mesurement.name && (mesurement.name === 'Wilgotność Powietrza' || mesurement.name === 'Temperatura Powietrza')" >

              <p class="parametr">{{ mesurement.name + ": "}} </p>  <p class="value">{{mesurement.value + " "  + mesurement.units }}
              <img
                class="trend-arrow"
                v-if="mesurement.value_5m < mesurement.value"
                :src="trendUp"
              />
              <img
                class="trend-arrow"
                v-if="mesurement.value_5m > mesurement.value"
                :src="trendDown"
              />
            </p>
          </div>
          </div>
        </div>
      </div>
    </div>
  
</template>

<script>
import { format, utcToZonedTime } from "date-fns-tz";

export default {
  components: {},
  data() {
    return {
      timeZone: "Europe/Warsaw",
      trendUp: require("@/assets/icons/TrendUp.svg"),
      trendDown: require("@/assets/icons/TrendDown.svg"),
      rackHeaders: [
        "Wilgotność powietrza",
        "Temperatura powietrza",
        "Ciśnienie atmosferyczne",
        "Stężenie CO<sub style='font-size: 0.6rem;'>2</sub>",
        "Stęzenie O<sub style='font-size: 0.6rem;'>2</sub>",
      ],
      nameMap: {
        rackA: "Regał-A",
        rackB: "Regał-B",
        rackC: "Regał-B"
      },
      
    };
  },
  props: {
    dataToShow: {
      type: Object,
      required: false,
    },
    showNotImportant: {
      type: Boolean,
      required: false,
      default: false,
    },
    rackName: {
      type: String,
   
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.responsive.isMobile;
    },
  },
  methods: {

    changeName(name) {

      const newName = this.nameMap[name] || name;

      return newName;
},

    checkLeftRight(sensor) {
      if (sensor === "N0.037" || sensor === "N0.042")
        return "Lewa Strona"
      else if (sensor === "N0.038" || sensor === "N0.046")
        return "Środek"
      else if (sensor === "N0.054" || sensor === "N0.039")
        return "Prawa Strona"
      else return 
    },
    isEven(index) {
      return parseInt(index) % 2 === 0;
    },
    
    timeFormater(time) {
      const originalDate = time;
      const timeZone = this.timeZone;
      const zonedDate = utcToZonedTime(originalDate, timeZone);

      // Sformatuj datę na czytelny format
      return format(zonedDate, "HH:mm", { timeZone });
    },
  },

};
</script>

<style scoped lang="scss">
@import "./../../main.scss";
.rack-border{
  background-color: $blue-green;
  width: 18rem;
  height: 1px;
}

.trend-arrow {
  width: 1rem;
}

.rack {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0;
  margin-bottom: 4rem;
}

.section-header {
  grid-column: 1 / -1;
}


.gray-background {
  background-color: $main-colour-light;
}

.cell {
  margin: 0.5rem 0;
}

.cell-header {
  margin: 0.5rem 0;
  span {
    margin-left: 1rem;
    font-weight: bold;
  }
}

.parametr {
  margin: 0.5rem 1rem;
  font-size: 1rem;
  font-family: BwGradualnormal;
  color: $main-colour-dark;
}

.value{
  font-family: BwGradual;
  color: $main-colour-dark;
  font-size: 1.2rem;
  margin-left: 1rem;
  margin-top: -0.8rem;
}

.position-rack{
  font-family: BwGradual;
  color: $main-colour-dark;
  font-size: 1.2rem;
  margin-left: 1rem;
  margin-top: 0.8rem;
}
</style>
