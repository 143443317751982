import router from "./index.js";

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !localStorage.getItem("userId")
  ) {
    next("/welcome");
  } else {
    next();
  }
});
