export default {
  getData(state, payload) {
    state.farms = payload;
  },
  getCropTemplates(state, payload) {
    state.cropTemplates = payload;
  },
  getGraphs(state, payload) {
    state.graphs = payload;
  },
  dataError(state, payload) {
    state.dataError = payload;
  },
};
