<template>
  <div class="info-panel">
   
    <base-dialog :show="!!this.errorShow" title="Błąd" @close="handleError">
      <p>{{ this.dataError }}</p>
    </base-dialog>
    <info-line>
      {{ this.dataError }}
    </info-line>

    
    <div v-for="(farm, farmName) in farmsToShow" :key="farm">
      <h4>{{ farmName }}</h4>
      <div v-if="farmName !=='Szklarnia 6' ">
      <div v-for="(data, rack) in getRackData(farm)" :key="rack">
          
            <DataRack
              :dataToShow="data"
              :rackName="rack"
              :showNotImportant="notImportentShow"
            ></DataRack>
              
          
            <DataBlock
              :farmName="farmName"
              :dataToShow="getNotRackData(farm)"
              :showNotImportant="notImportentShow"
              
            ></DataBlock>
           </div>
          </div>
          <div v-else>
            <Szklarnia6Data
            :farm="farm"         
            ></Szklarnia6Data>
          
       </div>
    </div>
    <div class="expand-button-container">
      <expand-button
        v-if="!notImportentShow"
        @click="isNotImportantShown()"
        class="expand-button"
        >więce informacji...</expand-button
      >
      <!-- 
      <expand-button
        v-if="notImportentShow"
        @click="isNotImportantShown()"
        class="expand-button"
        >mniej informacji...</expand-button
      >
      -->
    </div>
  </div>
</template>

<script>
import { format, utcToZonedTime } from "date-fns-tz";
import sensors from "@/data/sensorsNames";
import sensorsImportance from "@/data/sensorsImportance";
import sensorsUnit from "@/data/sensorsUnit";
//import sensorsPackages from "@/data/sensorsPackages";
import DataRack from "@/components/layout/DataRack.vue";
import DataBlock from "@/components/layout/DataBlock.vue";
import Szklarnia6Data from "@/components/layout/Szklarnia6Data.vue";

export default {
  name: "InfoPanel",
  components: {
    DataRack,
    DataBlock,
    Szklarnia6Data
  },
  props: {
    msg: String,
  },
  data() {
    return {
      errorCounter: 0,
      errorShow: false,
      dataError: "",
      getDataInterval: null,
      getErrorInterval: null,
      notImportentShow: true,
      farmsToShow: {},
      farms: [],
      templates: [],
      userId: "",
      
    };
  },
  watch: {
    dataError(newValue) {
      if (newValue !== "") {
        this.errorConut();
      } else {
        if (this.getErrorInterval !== null) {
          clearInterval(this.getErrorInterval);
          this.getErrorInterval = null;
        }
      }
      
    },
  },
  computed: {},
  methods: {
    getFarmStructure(farmName, rack){
      
      const farm = this.farms.find(farm => farm.farm_name === farmName);
        if (!farm) {
          return null;
        }
        const selectedRack = farm.farm_structure.shelves.find(shelf => shelf.id === rack);

        if (!selectedRack) {
          
          return null;
        }

      
      return selectedRack.shelves;
    
    },
    farmsToControl() {
      const farms = this.$store.getters["data/farms"];
      this.farms = farms;
      
    },
    getTemplates() {
      const templates = this.$store.getters["data/cropTemplates"];
      this.templates = templates;
      
    },

    async getFarmData() {
      this.isLoading = true;
      const fetchDataWithRetry = async (maxRetries) => {
        for (let retries = 0; retries < maxRetries; retries++) {
          try {
            await this.$store.dispatch("data/getData", { database: "farmsId" });
            await this.$store.dispatch("data/getData", {
              database: "cropTemplates",
            });
            this.dataError = this.$store.getters["data/dataError"];
            if (this.dataError === "") {
              break;
            }
          } catch (error) {
            console.error("Wystąpił błąd podczas pobierania danych", error);
          }
          await new Promise((resolve) => setTimeout(resolve, 2000));
        }
      };
      try {
        await fetchDataWithRetry(5);
        await this.farmsToControl();
        await this.getTemplates();
      } catch (error) {
        console.error("Wystąpił błąd podczas pobierania danych", error);
      }
      if (this.dataError !== "") {
        this.errorShow = true;
      }
      this.isLoading = false;
    },


    getRackData(farm) {
      
      const rackData = {};
      
      for (const sensor in farm) {
        if (Object.prototype.hasOwnProperty.call(farm, sensor)) {
          if (farm[sensor]['category'].substring(0, 4) === 'rack') {

            if (!rackData[farm[sensor]['category']]){
              rackData[farm[sensor]['category']] = {}
            }
            
            rackData[farm[sensor]['category']][sensor] = farm[sensor]
          }

        }
      }
      
      return rackData;
    },

    getNotRackData(farm) {
      const notRackData = {};
      
      for (const sensor in farm) {
        
        if (Object.prototype.hasOwnProperty.call(farm, sensor)) {
          if (farm[sensor]['category'].substring(0, 4) !== 'rack') {

            

            if (!notRackData[farm[sensor]['category']]){
              notRackData[farm[sensor]['category']] = {}
            }
            notRackData[farm[sensor]['category']][sensor] = farm[sensor]
          }

        }
      }
      
      return notRackData;
    },
    myFarms() {
      const farms = this.$store.getters["data/farms"];
      
      
      const modifiedFarms = {};

      for (const farm in farms) {

        if (Object.prototype.hasOwnProperty.call(farms, farm)) {

          const myFarm = farms[farm];
          
          const modifiedFarm = myFarm;
         
          
          modifiedFarms[farm] = modifiedFarm;
        }
      }
      
      this.farmsToShow = modifiedFarms;
      
    },

    

    addUnit(sensor) {
      return sensorsUnit[sensor];
    },
    changeDescription(name) {
      return sensors[name] || "NIEPOPRAWNA NAZWA CZUJNIKA";
    },
    isNotImportantShown() {
      if (this.notImportentShow) {
        this.notImportentShow = false;
      } else {
        this.notImportentShow = true;
      }
    },
    checkIfSensorImportant(sensor) {
      if (sensorsImportance[sensor]) {
        return true;
      } else {
        return false;
      }
    },
    errorConut() {
      this.errorShow = true;
      this.getErrorInterval = setInterval(() => {
        this.errorShow = true;
      }, 60000);
    },

    handleError() {
      this.errorShow = false;
    },
    timeFormater(time) {
      const originalDate = time;
      const timeZone = this.timeZone;
      const zonedDate = utcToZonedTime(originalDate, timeZone);

      // Sformatuj datę na czytelny format
      return format(zonedDate, "HH:mm", { timeZone });
    },

    async getInfluxData() {
      
      await this.$store.dispatch({
        type: "data/getData",
        database: "influx",
      });
      await this.myFarms();
    },



    getDataError() {
      this.dataError = this.$store.getters["data/dataError"];
    },
    startDataInterval() {
      this.getDataInterval = setInterval(() => {
        this.getInfluxData();
        this.getDataError();
      }, 10000);
    },
    stopInterval() {
      if (this.getDataInterval !== null) {
        clearInterval(this.getDataInterval);
        this.getDataInterval = null;
      }
    },
  },
  created() {
    
    console.log(this.userId)
    this.getInfluxData();
    this.getDataError();
    this.startDataInterval();
    this.getFarmData();
    
    
  },
  beforeUnmount() {
    this.stopInterval();
  },
};
</script>

<style scoped lang="scss">
@import "../main.scss";


h4{
  margin-top:4rem;
}
.info-panel {
  padding: 1rem;
  
}

.expand-button-container {
  margin-top: 2rem;
  margin-right: 0;
  text-align: right;
}
</style>
