<template>
  <div class="home">
    <InfoPanel />
  </div>
</template>

<script>
// @ is an alias to /src
import InfoPanel from "@/components/InfoPanel.vue";

export default {
  name: "HomeView",
  components: {
    InfoPanel,
  },
};
</script>
