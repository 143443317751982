<template>
  <header :class="{'header-mobile': isMobile}" >  
      <img :class="{'img-mobile': isMobile}" :src="logo" />
      <div class="header-content">
      <nav>
       
        <ul :key="userId">
        <li v-if="isLoggedIn && !isMobile ">
          <router-link :to="{ name: 'home' }"  class="nav-link" :class="{ 'active-link': isActiveHome }">Panel Informacji</router-link>
        </li>
        <li v-if="isLoggedIn && !isMobile" >
          <router-link :to="{ name: 'details' }"  class="nav-link" :class="{ 'active-link': isActiveDetails}">Informacje Szczegółowe</router-link>
        </li>
        <li v-if="isLoggedIn && !isMobile && userId != 7">
          <router-link :to="{ name: 'controls' }"  class="nav-link" :class="{ 'active-link': isActiveControls}">Szablony</router-link>
        </li>
        <li v-if="isLoggedIn && !isMobile && userId != 7">
          <router-link :to="{ name: 'controls2' }"  class="nav-link" :class="{ 'active-link': isActiveControls2}">Sterowanie</router-link>
        </li>
        <li v-if="isLoggedIn && !isMobile && userId != 7">
          <router-link :to="{ name: 'logs' }"  class="nav-link" :class="{ 'active-link': isActiveLogs}">Historia</router-link>
        </li>
        
        <li v-if="isLoggedIn && !isMobile">
          <base-button @click="logout">Wyloguj</base-button>
        </li>
      </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {

  data() {
    return {
      logo: require("@/assets/Leafmatic_logo.svg"),  
      
       
    };
  },
  watch: {
    userId: function(newUserId) {
      
      this.userId = newUserId
    },
  },
  computed: {
    userId() {
      
      return this.$store.getters.userId;
      
    },
    isLoggedIn() {
      
      return this.$store.getters.isAuthenticated;
    },
    isMobile() {
      return this.$store.state.responsive.isMobile;
    },
    isActiveHome() {
      return this.$route.name === 'home';
    },
    isActiveDetails() {
      return this.$route.name === 'details';
    },
    isActiveControls() {
      return this.$route.name === 'controls';
    },
    isActiveControls2() {
      return this.$route.name === 'controls2';
    },
    
    isActiveLogs() {
      return this.$route.name === 'logs';
    }
  },
  methods: {
    

    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/welcome");
    },
  },

};
</script>

<style scoped lang="scss">
@import "@/main.scss";

img {
  max-height: 4rem;
}

.img-mobile {
  max-height: 11rem;
}






header {
  width: 100%;
  height: 100px;
  display: flex;

  .header-content {
    width: 100%;
    margin-right: 0px;
    display: flex;
    
  }

  nav {
    flex-grow: 1;
  }
}
.header-mobile { 
  height: 9rem;
  align-items: flex-end;
}

header ul {
  width: 100%;
}





header a {
  font-size: 0.8rem;
  font-family: BwGradual;
  text-decoration: none;
  color: $main-colour-dark;
  display: inline-block;
  font-weight: normal;
  padding: 0.75rem 1rem;
  position: relative; // Dodajemy atrybut position: relative, aby pozycjonować ::before
  transition: color 0.3s ease, transform 0.3s ease; // Dodajemy przejścia dla koloru i transformacji
  &.hovered::before {
    content: ''; // Dodajemy pseudoelement ::before, który będzie pełnił rolę podkreślenia
    position: absolute;
    bottom: 0.6rem; // Odległość od dolnej krawędzi tekstu
    left: 0; // Odległość od lewej krawędzi tekstu
    right: 0; // Odległość od prawej krawędzi tekstu
    height: 0.3rem; // Grubość podkreślenia
    background-color: $blue-green-light; // Kolor podkreślenia
    transition: bottom 0.3s ease, background-color 0.3s ease; // Dodajemy przejścia dla pozycji i koloru
  }

  &:hover {
    text-decoration: underline $blue-green-light;
    font-weight: bold;

    &::before {
      content: ''; // Dodajemy pseudoelement ::before, który będzie pełnił rolę podkreślenia
      position: absolute;
      bottom: 0.6rem; // Odległość od dolnej krawędzi tekstu
      left: 0; // Odległość od lewej krawędzi tekstu
      right: 0; // Odległość od prawej krawędzi tekstu
      height: 0.2rem; // Grubość podkreślenia
      background-color: $blue-green-light; // Kolor podkreślenia
      transition: bottom 0.3s ease, background-color 0.3s ease; // Dodajemy przejścia dla pozycji i koloru
    }
  }

  &.active,
  &:focus {
    // Dodaj styl dla stanu aktywnego (naciśniętego) i aktywnej strony
    color: $main-colour-dark; // Ustaw kolor dla aktywnej strony
    font-weight: bold;

    &::before {
      bottom: -0.4rem; // Przesuń podkreślenie o 0.4rem w górę po naciśnięciu
      background-color: $main-colour-dark; // Zmiana koloru podkreślenia po naciśnięciu
    }
    
  }


}

.active-link {
  &::before {
    content: ''; // Dodajemy pseudoelement ::before, który będzie pełnił rolę podkreślenia
    position: absolute;
    bottom: -0.5rem; // Odległość od dolnej krawędzi tekstu
    left: 0; // Odległość od lewej krawędzi tekstu
    right: 0; // Odległość od prawej krawędzi tekstu
    height: 0.2rem; // Grubość podkreślenia
    background-color: $main-colour-dark; // Kolor podkreślenia
  }
}

.nav-link {
  
}




h1 {
  margin: 0;
}

h1 a {
  color: $white;
  margin: 0;
}

h1 a:hover,
h1 a:active,
h1 a.router-link-active {
  border-color: transparent;
}

header nav {
  width: 100%;
  margin: auto;
  margin-right: 0px;
}

header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-right: 2rem;
  display: flex;
  justify-content: right;
  align-items: center;
  
}

li {
  margin: 0rem;
}
</style>
